import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import sendIcon from "./assets/sendpng.png";
import sendIconHover from "./assets/sendpnginv.png";
import chatIcon from "./assets/chatIcon.png";
import chatIconBorderless from "./assets/chatIconBorderless.png";
import profileImage from "./assets/me2.png";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { resume } from "./components/Resume";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  Conversation,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const systemMessage = {
  role: "system",
  content:
    "Speak as if you are Dillion AI, the AI Assisstant for Dillion Babin, a software engineer with expertise in .NET, C#, JavaScript, and SQL. Use concise, friendly language answer questions and explain my background, work experience, expertise, contact info, and explain technical concepts clearly. here is my resume:${resume} if they ask for my phone number tell them its 623-313-8176 if they ask for my email its dillion@dillionbabin.com make sure to tell them they can send me an email and schedule a meeting on my calendar in the contact section on my website. if they ask you questions like questiong that have the word 'you' or 'your' for example 'when are you available for a meeting' you can answer with something like 'Dillion's availability can be seen in his calendar on the contact section of his website. make sure to give detailed answers to all of their questiosn about my work experience, background, and skills.'",
};

function App() {
  const [chatOpen, setChatOpen] = useState(false);
  const [inputMessage, setInputMessage] = useState(""); // State to handle input text
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const chatContainerRef = useRef(null);

  const toggleChat = () => setChatOpen(!chatOpen);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const handleSend = async (message) => {
    if (!message.trim()) return; // Prevent sending empty messages
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, newMessage];
      // Callback form to ensure scrolling happens after state update
      setTimeout(() => scrollToBottom(), 0); // setTimeout ensures the update is in the next event loop
      return updatedMessages;
    });

    setInputMessage(""); // Clear the input after sending
    await processMessageToChatGPT([...messages, newMessage]); // Make sure this doesn't affect scrolling
  };

  const simulateTyping = async (message, delay) => {
    setIsTyping(true);
    await new Promise((resolve) => setTimeout(resolve, delay));
    setIsTyping(false);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        message,
        direction: "incoming",
        sender: "Dillion AI",
        html: message.includes("<a href="),
      },
    ]);
  };

  const autoSendMessages = async () => {
    await simulateTyping(
      "Hi! I'm Dillion AI, an AI assistant designed by Dillion Babin.",
      2500
    );
    await simulateTyping(
      "I'm designed to answer all of your questions about Dillion's work experience, resume, expertise, and more",
      3000
    );
    await simulateTyping(
      "A copy of Dillion's Resume can be found here: <a href='/assets/DillionBabin-Resume.pdf' download='DillionBabin-Resume.pdf'>Download Resume</a> " +
        "and you can schedule a meeting or send an email from the <a href='#connect'>Contact Page</a>",
      3500
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setChatOpen(true);
      autoSendMessages();
    }, 3000);

    return () => clearTimeout(timer); // Clear timeout if component unmounts
  }, []);

  async function processMessageToChatGPT(chatMessages) {
    setIsTyping(true); // Start showing typing indicator when sending the request

    const apiMessages = [
      {
        role: "system",
        content: systemMessage.content,
      },
      ...chatMessages.map((messageObject) => ({
        role: messageObject.sender === "Dillion AI" ? "system" : "user",
        content: messageObject.message,
      })),
    ];

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: apiMessages,
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => data.json())
      .then((data) => {
        const responseMessage = data.choices[0].message.content;
        const modifiedMessage = responseMessage.replace(
          "${resume}",
          `<a href="/assets/DillionBabin-Resume.pdf" download="DillionBabin-Resume.pdf">Download Resume</a>`
        );

        // Add response to messages and hide typing indicator
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            message: modifiedMessage,
            direction: "incoming",
            sender: "Dillion AI",
            html: modifiedMessage.includes("<a href="),
          },
        ]);
        setIsTyping(false);
      })
      .catch(() => {
        setIsTyping(false); // Ensure to turn off typing indicator on error
      });
  }

  return (
    <div className="App">
      <button
        className={`chat-toggle ${chatOpen ? "gradient-border" : ""}`}
        onClick={toggleChat}
      >
        <img
          src={chatOpen ? chatIconBorderless : chatIcon}
          alt="Chat Icon"
          style={{ width: "120px", height: "120px" }}
        />
      </button>

      {chatOpen && (
        <div className="chat-container">
          <div className="profile-container">
            <img src={profileImage} alt="Profile" className="profile" />
          </div>
          <MainContainer>
            <ChatContainer ref={chatContainerRef}>
              <MessageList>
                {messages.map((message, i) => (
                  <Message key={i} model={message} />
                ))}
              </MessageList>
            </ChatContainer>

            <div className="input-container">
              {isTyping && (
                <TypingIndicator content="Dillion AI is typing..." />
              )}
              <input
                className="input-textbox"
                type="text"
                placeholder="Type your message..."
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSend(inputMessage)}
              />
              <button
                className="send-button"
                onClick={() => handleSend(inputMessage)}
                style={{
                  background: `url(${sendIcon}) no-repeat center center / cover`,
                  border: "none",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.background = `url(${sendIconHover}) no-repeat center center / cover`)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.background = `url(${sendIcon}) no-repeat center center / cover`)
                }
              ></button>
            </div>
          </MainContainer>
        </div>
      )}
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
