import { useState } from "react";
import "../App.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import "animate.css";
import TrackVisibility from "react-on-screen";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

export const Contact = () => {
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    start: "09:00 AM",
    end: "09:30 AM",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [time, setTime] = useState({
    hour: "09",
    minute: "00",
    period: "AM",
  });

  const isFormComplete = () => {
    return Object.values(formDetails).every((x) => x !== "");
  };

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleDateClick = (arg) => {
    if (selectedDate && arg.dateStr === selectedDate.date) {
      return;
    }
    setSelectedDate({
      date: arg.dateStr,
      startTime: "09:00",
      endTime: "10:00",
    });
  };

  const updateTime = (field, value) => {
    setTime((prev) => ({ ...prev, [field]: value }));
    // Update the main form state if necessary
    onFormUpdate("startTime", `${time.hour}:${time.minute} ${time.period}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormComplete()) {
      setStatus({
        success: false,
        message: "Please fill in all fields before submitting.",
      });
      return;
    } else {
      if (selectedDate == null) {
        setStatus({
          success: false,
          message: "Please select a date and time.",
        });
        return;
      }
    }

    setButtonText("Sending...");

    const formattedStartTime = `${time.hour}:${time.minute} ${time.period}`;
    const formattedEndTime = calculateEndTime(
      time.hour,
      time.minute,
      time.period
    );

    const payload = {
      dateDate: selectedDate.date, // Make sure this is a string, e.g., '2024-05-14'
      startTime: formattedStartTime,
      endTime: formattedEndTime,
      timezone: "EST",
      email: formDetails.email,
      name: `${formDetails.firstName} ${formDetails.lastName}`,
      phone: formDetails.phone,
      message: formDetails.message,
    };

    try {
      const powerAutomateURL =
        "https://prod-50.westus.logic.azure.com:443/workflows/e1a1d77f72034a07b9b002f7e26beb92/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=pGPA4qY1tN7aDc-c6nVhpiLJ4HJhAHAxEFzNGllSEco";
      const response = await fetch(powerAutomateURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      setStatus({
        success: true,
        message: "Meeting scheduled successfully!",
      });
      setFormDetails(formInitialDetails); // Reset form fields
    } catch (error) {
      console.error("Error scheduling meeting:", error);
      setStatus({
        success: true,
        message: "Meeting scheduled successfully!",
      });
      setFormDetails(formInitialDetails); // Reset form fields
    }

    setButtonText("Send");
  };

  function calculateEndTime(hour, minute, period) {
    let endHour = parseInt(hour);
    let endMinute = parseInt(minute) + 30;

    // Adjust minutes and hours if necessary
    if (endMinute >= 60) {
      endMinute -= 60;
      endHour += 1;
    }

    // Handle AM/PM transition
    if (endHour > 12) {
      endHour -= 12;
      period = period === "AM" ? "PM" : "AM";
    } else if (endHour === 12) {
      period = period === "AM" ? "PM" : "AM";
    }

    return `${endHour.toString().padStart(2, "0")}:${endMinute
      .toString()
      .padStart(2, "0")} ${period}`;
  }

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <Col>
              <Row>
                <Col>
                  <Form.Group controlId="timeGroup">
                    <div className="time-select-group">
                      <Form.Control
                        as="select"
                        className="time-select"
                        value={time.hour}
                        onChange={(e) => updateTime("hour", e.target.value)}
                      >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map(
                          (hour) => (
                            <option
                              key={hour}
                              value={hour.toString().padStart(2, "0")}
                            >
                              {hour}
                            </option>
                          )
                        )}
                      </Form.Control>
                      <Form.Control
                        as="select"
                        className="time-select"
                        value={time.minute}
                        onChange={(e) => updateTime("minute", e.target.value)}
                      >
                        {["00", "15", "30", "45"].map((minute) => (
                          <option key={minute} value={minute}>
                            {minute}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control
                        as="select"
                        className="time-select"
                        value={time.period}
                        onChange={(e) => updateTime("period", e.target.value)}
                      >
                        {["AM", "PM"].map((period) => (
                          <option key={period} value={period}>
                            {period}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                unselectAuto={false}
                select={(selectionInfo) => handleDateClick(selectionInfo)}
                nowIndicator={true}
                selectAllow={(selectInfo) => {
                  const today = new Date();
                  const currentDate = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate()
                  );
                  const selectedDate = new Date(selectInfo.startStr);

                  // Compare the dates
                  return selectedDate >= currentDate;
                }}
              />
            </Col>
          </Col>
          <Col md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Get In Touch - (623) 313-8176</h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col sm={6} className="px-1">
                        <input
                          type="text"
                          value={formDetails.firstName}
                          placeholder="First Name"
                          onChange={(e) =>
                            onFormUpdate("firstName", e.target.value)
                          }
                        />
                      </Col>
                      <Col sm={6} className="px-1">
                        <input
                          type="text"
                          value={formDetails.lastName}
                          placeholder="Last Name"
                          onChange={(e) =>
                            onFormUpdate("lastName", e.target.value)
                          }
                        />
                      </Col>
                      <Col sm={6} className="px-1">
                        <input
                          type="email"
                          value={formDetails.email}
                          placeholder="Email Address"
                          onChange={(e) =>
                            onFormUpdate("email", e.target.value)
                          }
                        />
                      </Col>
                      <Col sm={6} className="px-1">
                        <input
                          type="tel"
                          value={formDetails.phone}
                          placeholder="Phone No."
                          onChange={(e) =>
                            onFormUpdate("phone", e.target.value)
                          }
                        />
                      </Col>

                      <Col className="px-1">
                        <textarea
                          rows="6"
                          value={formDetails.message}
                          placeholder="Message"
                          onChange={(e) =>
                            onFormUpdate("message", e.target.value)
                          }
                        ></textarea>
                      </Col>

                      <Row>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <button type="submit" style={{ width: "30%" }}>
                            <span>{buttonText}</span>
                          </button>
                          {status.message && (
                            <p
                              className={
                                status.success === false ? "danger" : "success"
                              }
                            >
                              {status.message}
                            </p>
                          )}
                        </div>
                      </Row>
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
