import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/in/dillion-babin/"
                target="_blank"
              >
                <img src={navIcon1} alt="" />
              </a>
              <a
                href="https://github.com/DillionB"
                style={{
                  width: "43px",
                  height: "43px",
                  transform: "translateY(9px)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 16 16"
                  className="github-logo"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 .665a7.335 7.335 0 0 0-2.328 14.334c.367.068.5-.158.5-.352v-1.223c-2.045.447-2.476-1.01-2.476-1.01-.334-.853-.817-1.082-.817-1.082-.668-.457.052-.448.052-.448.738.052 1.124.758 1.124.758.655 1.124 1.717.798 2.133.61.067-.457.253-.798.46-.98-1.61-.183-3.3-.798-3.3-3.547 0-.785.28-1.428.758-1.93-.076-.183-.328-.91.072-1.898 0 0 .605-.183 1.98.733a6.609 6.609 0 0 1 1.782-.244 6.616 6.616 0 0 1 1.782.244c1.375-.916 1.98-.733 1.98-.733.4.988.148 1.715.072 1.898.477.502.758 1.145.758 1.93 0 2.756-1.691 3.364-3.296 3.542.259.223.5.664.5 1.347v2.002c0 .191.133.42.5.352A7.335 7.335 0 0 0 8 .665z"
                  />
                </svg>
              </a>
            </div>
            <p>Created by Dillion Babin V2.1</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
